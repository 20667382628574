<template>
  <div>
    <div>
      <el-button v-throttle size="medium" @click="goBack">返回</el-button>
    </div>

    <div id="question_box">
      <div class="badge">
        <span>题目名称</span>
        <span>{{ companQuestionInfo.name }}</span>
      </div>
      <div class="badge">
        <span>考题编码</span>
        <span>{{ companQuestionInfo.code }}</span>
      </div>
      <div class="badge">
        <span>相关学科</span>
        <span v-if="companQuestionInfo.subjectType == 1">物理</span>
        <span v-else-if="companQuestionInfo.subjectType == 2">生物</span>
        <span v-else-if="companQuestionInfo.subjectType == 3">化学</span>
        <span v-else>未知</span>
      </div>
      <div>
        <el-row>
          <el-col :span="1" id="scoringCriteria">评分标准</el-col>
          <el-col :span="11" id="questionTable">
            <el-table
              v-loading="loading"
              border
              :data="companQuestionInfo.operationItemList"
              tooltip-effect="dark"
              style="width: 100%"
              header-align="center"
              :cell-style="{ 'text-align': 'center' }"
              show-summary
            >
              <el-table-column
                align="center"
                prop="sortNum"
                label="序号"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                align="center"
                prop="name"
                label="评分项"
                show-overflow-tooltip
                :min-width="540"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="score"
                label="分数"
                show-overflow-tooltip
              ></el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { getCompanQuestionInfoApi } from '@/api/schoolManufacturer/questionInfo.js'
import { isEmpty } from '@/utils/util.js'
export default {
  name: 'questionInfo',
  data() {
    return {
      urlParam: {
        operationTestId: 0,
      },
      loading: false,
      companQuestionInfo: {},
    }
  },
  mounted() {
    this.urlParam.operationTestId = this.$route.query.operationTestId
    if (
      isEmpty(this.urlParam.operationTestId) ||
      this.urlParam.operationTestId == 0
    ) {
      return
    }
    this.getCompanQuestionInfo()
  },
  methods: {
    goBack() {
      this.$router.push({
        path: '/schoolManufacturer/baseData',
      })
    },
    //获取考题明细
    getCompanQuestionInfo() {
      this.loading = true
      getCompanQuestionInfoApi(this.urlParam.operationTestId)
        .then((res) => {
          this.loading = false
          if (res.success) {
            this.companQuestionInfo = {}
            this.companQuestionInfo = res.data
            this.companQuestionInfo.operationItemList.forEach((item, index) => {
              item.sortNum = index + 1
            })
          } else {
            if (res.code != 500) {
              this.msgWarning(res.msg)
            }
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
#question_box {
  margin: 20px 0 0 30px;
}

#question_box > div {
  margin-bottom: 12px;
}

.badge > span:first-child,
#scoringCriteria {
  font-size: 15px;
  font-weight: bold;
}

.badge > span:last-child {
  margin-left: 10px;
}

#questionTable {
  margin-left: 3px;
}
</style>
